import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { MediaResource, MediaResourceJson } from "@/models/MediaResource";

interface InformationJson {
  temple_id: string;
  timestamp: number;
  title: string;
  body: string;
  media_urls: MediaResourceJson[];
  available_from: string | null;
  available_to: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface InformationSearchResult {
  information: Information[];
  last_evaluated_key: string | null;
}

export default class Information {
  temple_id: string;
  timestamp: number;
  title: string;
  body: string;
  media_urls: MediaResource[];
  available_from: Date | null;
  available_to: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor(
    temple_id: string,
    timestamp: number,
    title: string,
    body: string,
    media_urls: MediaResource[],
    available_from: Date | null,
    available_to: Date | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.temple_id = temple_id;
    this.timestamp = timestamp;
    this.title = title;
    this.body = body;
    this.media_urls = media_urls;
    this.available_from = available_from;
    this.available_to = available_to;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(temple_id: string, timestamp: number): Promise<Information> {
    console.log(`Information.get(${temple_id}, ${timestamp})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/information/${temple_id}_${timestamp}`,
      config
    );

    const json = response.data.data as InformationJson;

    return new Information(
      json.temple_id,
      json.timestamp,
      json.title,
      json.body,
      json.media_urls,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    temple_id: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<InformationSearchResult> {
    console.log(
      `Information.search(${temple_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        temple_id,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/information`,
      config
    );

    const list: Information[] = [];
    response.data.data.forEach((json: InformationJson) => {
      const resources: MediaResource[] = [];

      if (json.media_urls) {
        json.media_urls.forEach((mediaJson) =>
          resources.push(MediaResource.fromJson(mediaJson))
        );
      }

      list.push(
        new Information(
          json.temple_id,
          json.timestamp,
          json.title,
          json.body,
          resources,
          json.available_from ? new Date(json.available_from) : null,
          json.available_to ? new Date(json.available_to) : null,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      information: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    temple_id: string,
    title: string,
    body: string,
    available_from: Date | null,
    available_to: Date | null
  ): Promise<Information> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/information`,
      {
        temple_id: temple_id,
        title: title,
        body: body,
        available_from:
          available_from != null
            ? moment(available_from).format("YYYY-MM-DD")
            : null,
        available_to:
          available_to != null
            ? moment(available_to).format("YYYY-MM-DD")
            : null,
      },
      config
    );

    console.log(response);

    const json = response.data as InformationJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }

    return new Information(
      json.temple_id,
      json.timestamp,
      json.title,
      json.body,
      resources,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    temple_id: string,
    timestamp: number,
    title: string,
    body: string,
    available_from: Date | null,
    available_to: Date | null
  ): Promise<Information> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/information/${temple_id}_${timestamp}`,
      {
        temple_id: temple_id,
        timestamp: timestamp,
        title: title,
        body: body,
        available_from:
          available_from != null
            ? moment(available_from).format("YYYY-MM-DD")
            : null,
        available_to:
          available_to != null
            ? moment(available_to).format("YYYY-MM-DD")
            : null,
      },
      config
    );

    console.log(response);

    const json = response.data as InformationJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }

    return new Information(
      json.temple_id,
      json.timestamp,
      json.title,
      json.body,
      resources,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(
    temple_id: string,
    timestamp: number
  ): Promise<Information> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/information/${temple_id}_${timestamp}`,
      config
    );

    console.log(response);

    const json = response.data as InformationJson;

    const resources: MediaResource[] = [];

    if (json.media_urls) {
      json.media_urls.forEach((mediaJson) =>
        resources.push(MediaResource.fromJson(mediaJson))
      );
    }

    return new Information(
      json.temple_id,
      json.timestamp,
      json.title,
      json.body,
      resources,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
