import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import Followable from "./Followable";

export interface DecedentJson {
  id: string;
  username: string;
  is_celebrity: boolean;
  selfie_image_url: string | null;
  name: string;
  family_name: string | null;
  given_name: string | null;
  family_name_kana: string | null;
  given_name_kana: string | null;
  nickname: string | null;
  buddist_name: string | null;
  buddist_name_kana: string | null;
  date_of_birth: string | null;
  date_of_dead: string | null;
  age: string | null;
  chief_mourner: string | null;
  introduce: string | null;
  birthplace: string | null;
  hobby: string | null;
  favorite_food: string | null;
  school: string | null;
  job: string | null;
  conferment: string | null;
  follower_count: number;
  post_count: number;
  timeline_published_time: string | null;
  read_time: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface DecedentSearchResult {
  decedents: Decedent[];
  last_evaluated_key: string | null;
}

export default class Decedent extends Followable {
  id: string;
  username: string;
  is_celebrity: boolean;
  selfie_image_url: string | null;
  name: string;
  family_name: string | null;
  given_name: string | null;
  family_name_kana: string | null;
  given_name_kana: string | null;
  nickname: string | null;
  buddist_name: string | null;
  buddist_name_kana: string | null;
  date_of_birth: string | null;
  date_of_dead: string | null;
  age: string | null;
  chief_mourner: string | null;
  introduce: string | null;
  birthplace: string | null;
  hobby: string | null;
  favorite_food: string | null;
  school: string | null;
  job: string | null;
  conferment: string | null;
  follower_count: number;
  post_count: number;
  timeline_published_time: string | null;
  read_time: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  override get type(): string {
    return "decedent";
  }

  override get display_name(): string {
    return !this.nickname
      ? `故 ${this.family_name} ${this.given_name}`
      : `故 ${this.nickname}`;
  }

  get hasNew(): boolean {
    if (!this.timeline_published_time) {
      return false;
    }
    if (!this.read_time) {
      return true;
    }
    return this.timeline_published_time!.localeCompare(this.read_time) > 0;
  }

  constructor(
    id: string,
    username: string,
    is_celebrity: boolean,
    selfie_image_url: string | null,
    name: string,
    family_name: string | null,
    given_name: string | null,
    family_name_kana: string | null,
    given_name_kana: string | null,
    nickname: string | null,
    buddist_name: string | null,
    buddist_name_kana: string | null,
    date_of_birth: string | null,
    date_of_dead: string | null,
    age: string | null,
    chief_mourner: string | null,
    introduce: string | null,
    birthplace: string | null,
    hobby: string | null,
    favorite_food: string | null,
    school: string | null,
    job: string | null,
    conferment: string | null,
    follower_count: number,
    post_count: number,
    timeline_published_time: string | null,
    read_time: string | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    super(id, username, selfie_image_url);
    this.id = id;
    this.username = username;
    this.is_celebrity = is_celebrity;
    this.selfie_image_url = selfie_image_url;
    this.name = name;
    this.family_name = family_name;
    this.given_name = given_name;
    this.family_name_kana = family_name_kana;
    this.given_name_kana = given_name_kana;
    this.nickname = nickname;
    this.buddist_name = buddist_name;
    this.buddist_name_kana = buddist_name_kana;
    this.date_of_birth = date_of_birth;
    this.date_of_dead = date_of_dead;
    this.age = age;
    this.chief_mourner = chief_mourner;
    this.introduce = introduce;
    this.birthplace = birthplace;
    this.hobby = hobby;
    this.favorite_food = favorite_food;
    this.school = school;
    this.job = job;
    this.conferment = conferment;
    this.follower_count = follower_count;
    this.post_count = post_count;
    this.timeline_published_time = timeline_published_time;
    this.read_time = read_time;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  setRead() {
    this.read_time = this.timeline_published_time;
  }

  static async get(id: string): Promise<Decedent> {
    console.log(`Decedent.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/decedents/${id}`,
      config
    );

    const json = response.data.data as DecedentJson;

    return new Decedent(
      json.id,
      json.username,
      json.is_celebrity,
      json.selfie_image_url,
      json.name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.nickname,
      json.buddist_name,
      json.buddist_name_kana,
      json.date_of_birth,
      json.date_of_dead,
      json.age,
      json.chief_mourner,
      json.introduce,
      json.birthplace,
      json.hobby,
      json.favorite_food,
      json.school,
      json.job,
      json.conferment,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async getMyDecedents(customer_id: string): Promise<Decedent[]> {
    console.log(`Decedent.getMyDecedents(${customer_id})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        customer_id: customer_id,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/decedents`,
      config
    );

    const list: Decedent[] = [];
    response.data.data.forEach((json: DecedentJson) => {
      list.push(
        new Decedent(
          json.id,
          json.username,
          json.is_celebrity,
          json.selfie_image_url,
          json.name,
          json.family_name,
          json.given_name,
          json.family_name_kana,
          json.given_name_kana,
          json.nickname,
          json.buddist_name,
          json.buddist_name_kana,
          json.date_of_birth,
          json.date_of_dead,
          json.age,
          json.chief_mourner,
          json.introduce,
          json.birthplace,
          json.hobby,
          json.favorite_food,
          json.school,
          json.job,
          json.conferment,
          json.follower_count,
          json.post_count,
          json.timeline_published_time,
          json.read_time,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return list;
  }

  static async search(
    keyword: string | null,
    celebrity: boolean | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<DecedentSearchResult> {
    console.log(`Decedent.search(${keyword}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        keyword,
        last_evaluated_key,
        celebrity: celebrity ? `${celebrity}` : null,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/decedents`,
      config
    );

    const list: Decedent[] = [];
    response.data.data.forEach((json: DecedentJson) => {
      list.push(
        new Decedent(
          json.id,
          json.username,
          json.is_celebrity,
          json.selfie_image_url,
          json.name,
          json.family_name,
          json.given_name,
          json.family_name_kana,
          json.given_name_kana,
          json.nickname,
          json.buddist_name,
          json.buddist_name_kana,
          json.date_of_birth,
          json.date_of_dead,
          json.age,
          json.chief_mourner,
          json.introduce,
          json.birthplace,
          json.hobby,
          json.favorite_food,
          json.school,
          json.job,
          json.conferment,
          json.follower_count,
          json.post_count,
          json.timeline_published_time,
          json.read_time,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      decedents: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async searchCelebrityDecedent(
    temple_id: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<DecedentSearchResult> {
    console.log(
      `Decedent.searchCelebrityDecedent(${temple_id}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        temple_id,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/decedents`,
      config
    );

    const list: Decedent[] = [];
    response.data.data.forEach((json: DecedentJson) => {
      list.push(
        new Decedent(
          json.id,
          json.username,
          json.is_celebrity,
          json.selfie_image_url,
          json.name,
          json.family_name,
          json.given_name,
          json.family_name_kana,
          json.given_name_kana,
          json.nickname,
          json.buddist_name,
          json.buddist_name_kana,
          json.date_of_birth,
          json.date_of_dead,
          json.age,
          json.chief_mourner,
          json.introduce,
          json.birthplace,
          json.hobby,
          json.favorite_food,
          json.school,
          json.job,
          json.conferment,
          json.follower_count,
          json.post_count,
          json.timeline_published_time,
          json.read_time,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      decedents: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    temple_id: string | null,
    selfie_image_url: string,
    family_name: string | null,
    given_name: string | null,
    family_name_kana: string | null,
    given_name_kana: string | null,
    nickname: string | null,
    buddist_name: string | null,
    buddist_name_kana: string | null,
    date_of_birth: string | null,
    date_of_dead: string | null,
    age: number | null,
    introduce: string | null,
    birthplace: string | null,
    hobby: string | null,
    favorite_food: string | null,
    school: string | null,
    job: string | null
  ): Promise<Decedent> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/decedents`,
      {
        temple_id: temple_id,
        selfie_image_url,
        name,
        family_name,
        given_name,
        family_name_kana,
        given_name_kana,
        nickname,
        buddist_name,
        buddist_name_kana,
        date_of_birth: date_of_birth
          ? moment(date_of_birth).format("YYYY-MM-DD")
          : null,
        date_of_dead: date_of_dead
          ? moment(date_of_dead).format("YYYY-MM-DD")
          : null,
        age,
        introduce,
        birthplace,
        hobby,
        favorite_food,
        school,
        job,
        is_celebrity: true,
      },
      config
    );

    console.log(response);

    const json = response.data as DecedentJson;

    return new Decedent(
      json.id,
      json.username,
      json.is_celebrity,
      json.selfie_image_url,
      json.name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.nickname,
      json.buddist_name,
      json.buddist_name_kana,
      json.date_of_birth,
      json.date_of_dead,
      json.age,
      json.chief_mourner,
      json.introduce,
      json.birthplace,
      json.hobby,
      json.favorite_food,
      json.school,
      json.job,
      json.conferment,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    selfie_image_url: string,
    family_name: string | null,
    given_name: string | null,
    family_name_kana: string | null,
    given_name_kana: string | null,
    nickname: string | null,
    buddist_name: string | null,
    buddist_name_kana: string | null,
    date_of_birth: string | null,
    date_of_dead: string | null,
    age: string | null,
    introduce: string | null,
    birthplace: string | null,
    hobby: string | null,
    school: string | null,
    job: string | null,
    conferment: string | null
  ): Promise<Decedent> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/decedents/${id}`,
      {
        id,
        selfie_image_url,
        family_name,
        given_name,
        family_name_kana,
        given_name_kana,
        nickname,
        buddist_name,
        buddist_name_kana,
        date_of_birth,
        date_of_dead,
        age: `${age}`,
        introduce,
        birthplace,
        hobby,
        school,
        job,
        conferment,
      },
      config
    );

    console.log(response);

    const json = response.data as DecedentJson;

    return new Decedent(
      json.id,
      json.username,
      json.is_celebrity,
      json.selfie_image_url,
      json.name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.nickname,
      json.buddist_name,
      json.buddist_name_kana,
      json.date_of_birth,
      json.date_of_dead,
      json.age,
      json.chief_mourner,
      json.introduce,
      json.birthplace,
      json.hobby,
      json.favorite_food,
      json.school,
      json.job,
      json.conferment,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Decedent> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/decedents/${id}`,
      config
    );

    console.log(response);

    const json = response.data as DecedentJson;

    return new Decedent(
      json.id,
      json.username,
      json.is_celebrity,
      json.selfie_image_url,
      json.name,
      json.family_name,
      json.given_name,
      json.family_name_kana,
      json.given_name_kana,
      json.nickname,
      json.buddist_name,
      json.buddist_name_kana,
      json.date_of_birth,
      json.date_of_dead,
      json.age,
      json.chief_mourner,
      json.introduce,
      json.birthplace,
      json.hobby,
      json.favorite_food,
      json.school,
      json.job,
      json.conferment,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
