import Amplify from "aws-amplify";
import * as AmplifyModules from "aws-amplify";

const config = {
  aws_project_region: process.env.VUE_APP_AWS_REGION,
  aws_cognito_identity_pool_id:
    process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.VUE_APP_AWS_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_COGNITO_CLIENT_ID,
  oauth: {},
  Auth: {
    identityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: `terraspace.auth.ap-northeast-1.amazoncognito.com`,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: "http://localhost:8080",
      redirectSignOut: "http://localhost:8080",
      responseType: "code",
    },
  },
  Storage: {
    region: process.env.VUE_APP_AWS_REGION,
    bucket: process.env.VUE_APP_AWS_FILES_BUCKET,
  },
};

console.log(config);

Amplify.configure(config);

const languageDict = {
  ja: {
    "Sign In": "ログイン",
    "Signing in": "ログイン中...",
    "Sign Up": "登録",
    "Sign Out": "ログアウト",
    "Forgot Password": "パスワードを忘れた",
    Username: "ユーザー名",
    Password: "パスワード",
    "Change Password": "パスワードを変更",
    "New password": "新しいパスワード",
    "Confirm Password": "新しいパスワード(確認)",
    Email: "メールアドレス",
    "Phone Number": "電話番号",
    "Confirm a Code": "コードを確認",
    "Confirmation Code": "確認コード",
    "Confirm Sign In": "ログインの確認",
    "Confirm Sign Up": "登録の確認",
    "Back to Sign In": "ログインに戻る",
    "Send Code": "送信",
    Confirm: "確認",
    "Resend a Code": "コードをもう一度送る",
    "Resend Code": "コードをもう一度送る",
    Submit: "送信",
    Skip: "スキップ",
    Verify: "検証",
    "Verify Contact": "コンタクトを検証",
    Code: "コード",
    "Account recovery requires verified contact information":
      "コンタクトの情報を検証する為に、アカウントの回復が必要です",
    "User does not exist": "IDまたはパスワードが違います",
    "User already exists": "すでに存在するユーザーIDです",
    "Incorrect username or password.": "IDまたはパスワードが違います",
    "Invalid password format": "パスワードのフォマットが無効です",
    "Invalid phone number format": " ",
    "Sign in to your account": "サインイン",
    "Forgot your password? ": "パスワードを忘れた方はこちら",
    "Reset password": "パスワードをリセット",
    "No account? ": "",
    "Create account": "新しいアカウントを登録",
    "Create Account": "登録",
    "Have an account? ": "",
    "Sign in": "ログイン",
    "Create a new account": "新しいアカウントを作成",
    "Reset your Password": "パスワードをリセット",
    "Reset your password": "パスワードをリセット",
    "Enter your username": "ログインIDを入力してください",
    "Enter your password": "",
    "Enter new password": "パスワードを入力してください",
    "Enter your code": "",
    "Lost your code? ": "",
    "An account with the given email already exists.":
      "メールアドレスは他のアカウントに使っています",
    "Username cannot be empty": "ユーザー名を入力してください",
  },
};
AmplifyModules.I18n.putVocabularies(languageDict);
