import { Auth, Hub } from "aws-amplify";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import store from "@/store";

import HomeView from "../views/HomeView.vue";
import SearchView from "../views/SearchView.vue";
import TempleDetailView from "../views/TempleDetailView.vue";
import DecedentDetailView from "../views/DecedentDetailView.vue";
import EditDecedentProfileView from "../views/EditDecedentProfileView.vue";
import TimelineDetailView from "../views/TimelineDetailView.vue";
import InputWishView from "../views/InputWishView.vue";
import InputPrayView from "../views/InputPrayView.vue";
import EditProfileView from "../views/EditProfileView.vue";
import OrderHistoryView from "../views/OrderHistoryView.vue";
import MyDecedentsView from "../views/MyDecedentsView.vue";
import LoginView from "../views/LoginView.vue";
import SignUpView from "../views/SignUpView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import OAuthCallbackView from "../views/OAuthCallbackView.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfUse from "../views/TermsOfUse.vue";
import Commerce from "../views/Commerce.vue";
import { HubCallback } from "@aws-amplify/core";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/search",
    name: "Search",
    component: SearchView,
  },
  {
    path: "/temples/:temple_id",
    name: "TempleDetailView",
    component: TempleDetailView,
  },
  {
    path: "/decedents/:decedent_id",
    name: "DecedentDetailView",
    component: DecedentDetailView,
  },
  {
    path: "/decedents/:decedent_id/edit",
    name: "EditDecedentProfileView",
    component: EditDecedentProfileView,
  },
  {
    path: "/timelines/:target_id/:timestamp",
    name: "TimelineDetailView",
    component: TimelineDetailView,
  },
  {
    path: "/order/wish/:temple_id",
    name: "InputWishView",
    component: InputWishView,
  },
  {
    path: "/order/pray/:temple_id",
    name: "InputPrayView",
    component: InputPrayView,
  },
  {
    path: "/me/profile",
    name: "EditProfileView",
    component: EditProfileView,
  },
  {
    path: "/me/orders",
    name: "OrderHistoryView",
    component: OrderHistoryView,
  },
  {
    path: "/me/decedents",
    name: "MyDecedentsView",
    component: MyDecedentsView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUpView,
  },
  {
    path: "/reset",
    name: "ResetPassword",
    component: ResetPasswordView,
  },
  {
    path: "/oauth/:type/callback",
    name: "OAuthCallback",
    component: OAuthCallbackView,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/termsofuse",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/commerce",
    name: "Commerce",
    component: Commerce,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(`beforeEach(${to.path}, ${from.path})`);

  let userInfo = null;

  try {
    userInfo = await Auth.currentUserInfo();
  } catch (ex) {
    // ignore
  }

  if (
    !userInfo &&
    to.fullPath !== "/privacypolicy" &&
    to.fullPath !== "/termsofuse" &&
    to.fullPath !== "/commerce" &&
    to.fullPath !== "/login" &&
    to.fullPath !== "/signup" &&
    to.fullPath !== "/reset" &&
    !to.path.startsWith("/oauth")
  ) {
    next("/login");
    return;
  }

  if (userInfo && !store.state.customer) {
    await store.dispatch("refreshUser");
  }

  if (userInfo && to.fullPath == "/login") {
    next("/");
    return;
  }

  next();
});

const authListener: HubCallback = ({ payload: { event /*, data */ } }) => {
  console.log(`Auth event = ${event}`);
  switch (event) {
    case "signIn":
      store.dispatch("signIn");
      console.log("a");
      router.replace("/");
      break;
    case "signOut":
      router.replace("/login");
      break;
  }
};

Hub.listen("auth", authListener);

export default router;
