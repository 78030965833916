import * as yup from "yup";

yup.addMethod<yup.StringSchema>(yup.string, "kana", function () {
  return this.test(
    "kana",
    "カタカナで入力して下さい",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/^[ァ-ヶー　 ]+$/);
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "phonenumber", function () {
  return this.test(
    "phonenumber",
    "電話番号が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/\d{2,4}-\d{2,4}-\d{4}/);
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "postcode", function () {
  return this.test(
    "postcode",
    "郵便番号が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/^[0-9]{3}-[0-9]{4}$/);
      return !!match;
    }
  );
});

yup.addMethod<yup.StringSchema>(yup.string, "accountno", function () {
  return this.test(
    "accountno",
    "口座番号が不正です",
    (value: string | undefined): boolean => {
      if (value == null || value === "") {
        return true;
      }
      // eslint-disable-next-line no-irregular-whitespace
      const match = value.match(/^[0-9]{3,7}$/);
      return !!match;
    }
  );
});
