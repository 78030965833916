export default class ValidationUtil {
  static checkKana(value: string): boolean {
    // eslint-disable-next-line no-irregular-whitespace
    const match = value.match(/^[ァ-ヶー　 ]+$/);
    return !!match;
  }

  static checkPhoneNumber(value: string): boolean {
    // eslint-disable-next-line no-irregular-whitespace
    const match =
      value.match(/\d{2,4}-\d{2,4}-\d{4}/) ||
      value.match(/\d{2,4}\d{2,4}\d{4}/);
    return !!match;
  }

  static checkPostCode(value: string): boolean {
    // eslint-disable-next-line no-irregular-whitespace
    const match =
      value.match(/^[0-9]{3}-[0-9]{4}$/) || value.match(/^[0-9]{3}[0-9]{4}$/);
    return !!match;
  }
}
