import { Auth } from "aws-amplify";
import axios from "axios";
import Followable from "./Followable";

export interface TempleJson {
  id: string;
  username: string;
  name: string;
  kana: string;
  selfie_image_url: string | null;
  main_image_url: string | null;
  has_wish_menu: boolean;
  has_memorial_menu: boolean;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string | null;
  telephone: string | null;
  email: string | null;
  website: string | null;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: string;
  account_no: string;
  account_name: string;
  follower_count: number;
  post_count: number;
  timeline_published_time: string | null;
  read_time: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface TempleSearchResult {
  temples: Temple[];
  last_evaluated_key: string | null;
}

export default class Temple extends Followable {
  name: string;
  kana: string;
  main_image_url: string | null;
  has_wish_menu: boolean;
  has_memorial_menu: boolean;
  postcode: string;
  prefecture: string;
  city: string;
  address: string;
  building: string | null;
  telephone: string | null;
  email: string | null;
  website: string | null;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: string;
  account_no: string;
  account_name: string;
  follower_count: number;
  post_count: number;
  timeline_published_time: string | null;
  read_time: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  override get type(): string {
    return "temple";
  }

  override get display_name(): string {
    return this.name;
  }

  get full_address(): string {
    return `${this.prefecture}${this.city}${this.address}${
      this.building ?? ""
    }`;
  }

  get hasNew(): boolean {
    if (!this.timeline_published_time) {
      return false;
    }
    if (!this.read_time) {
      return true;
    }
    return this.timeline_published_time!.localeCompare(this.read_time) > 0;
  }

  constructor(
    id: string,
    username: string,
    name: string,
    kana: string,
    selfie_image_url: string | null,
    main_image_url: string | null,
    has_wish_menu: boolean,
    has_memorial_menu: boolean,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string | null,
    telephone: string | null,
    email: string | null,
    website: string | null,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string,
    follower_count: number,
    post_count: number,
    timeline_published_time: string | null,
    read_time: string | null,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    super(id, username, selfie_image_url);
    this.name = name;
    this.kana = kana;
    this.main_image_url = main_image_url;
    this.has_wish_menu = has_wish_menu;
    this.has_memorial_menu = has_memorial_menu;
    this.postcode = postcode;
    this.prefecture = prefecture;
    this.city = city;
    this.address = address;
    this.building = building;
    this.telephone = telephone;
    this.email = email;
    this.website = website;
    this.bank_code = bank_code;
    this.bank_name = bank_name;
    this.branch_code = branch_code;
    this.branch_name = branch_name;
    this.account_type = account_type;
    this.account_no = account_no;
    this.account_name = account_name;
    this.follower_count = follower_count;
    this.post_count = post_count;
    this.timeline_published_time = timeline_published_time;
    this.read_time = read_time;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  setRead() {
    this.read_time = this.timeline_published_time;
  }

  static async get(id: string): Promise<Temple> {
    console.log(`Temple.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/temples/${id}`,
      config
    );

    const json = response.data.data as TempleJson;

    return new Temple(
      json.id,
      json.username,
      json.name,
      json.kana,
      json.selfie_image_url,
      json.main_image_url,
      json.has_wish_menu,
      json.has_memorial_menu,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.email,
      json.website,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<TempleSearchResult> {
    console.log(`Temple.search(${keyword}, ${last_evaluated_key}, ${limit})`);
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        keyword,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/temples`,
      config
    );

    const list: Temple[] = [];
    response.data.data.forEach((json: TempleJson) => {
      list.push(
        new Temple(
          json.id,
          json.username,
          json.name,
          json.kana,
          json.selfie_image_url,
          json.main_image_url,
          json.has_wish_menu,
          json.has_memorial_menu,
          json.postcode,
          json.prefecture,
          json.city,
          json.address,
          json.building,
          json.telephone,
          json.email,
          json.website,
          json.bank_code,
          json.bank_name,
          json.branch_code,
          json.branch_name,
          json.account_type,
          json.account_no,
          json.account_name,
          json.follower_count,
          json.post_count,
          json.timeline_published_time,
          json.read_time,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      temples: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    name: string,
    kana: string,
    selfie_image_url: string | null,
    main_image_url: string | null,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string | null,
    telephone: string | null,
    email: string | null,
    website: string | null,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string,
    administrator_name: string,
    administrator_kana: string,
    administrator_email: string
  ): Promise<Temple> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/temples`,
      {
        name: name,
        kana: kana,
        selfie_image_url: selfie_image_url,
        main_image_url: main_image_url,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
        email: email,
        website: website,
        bank_code: bank_code,
        bank_name: bank_name,
        branch_code: branch_code,
        branch_name: branch_name,
        account_type: account_type,
        account_no: account_no,
        account_name: account_name,
        administrator_name: administrator_name,
        administrator_kana: administrator_kana,
        administrator_email: administrator_email,
      },
      config
    );

    console.log(response);

    const json = response.data as TempleJson;

    return new Temple(
      json.id,
      json.username,
      json.name,
      json.kana,
      json.selfie_image_url,
      json.main_image_url,
      json.has_wish_menu,
      json.has_memorial_menu,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.email,
      json.website,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    name: string,
    kana: string,
    selfie_image_url: string | null,
    main_image_url: string | null,
    postcode: string,
    prefecture: string,
    city: string,
    address: string,
    building: string | null,
    telephone: string | null,
    email: string | null,
    website: string | null,
    bank_code: string,
    bank_name: string,
    branch_code: string,
    branch_name: string,
    account_type: string,
    account_no: string,
    account_name: string
  ): Promise<Temple> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/temples/${id}`,
      {
        id,
        name,
        kana,
        selfie_image_url: selfie_image_url,
        main_image_url: main_image_url,
        postcode: postcode,
        prefecture: prefecture,
        city: city,
        address: address,
        building: building,
        telephone: telephone,
        email: email,
        website: website,
        bank_code: bank_code,
        bank_name: bank_name,
        branch_code: branch_code,
        branch_name: branch_name,
        account_type: account_type,
        account_no: account_no,
        account_name: account_name,
      },
      config
    );

    console.log(response);

    const json = response.data as TempleJson;

    return new Temple(
      json.id,
      json.username,
      json.name,
      json.kana,
      json.selfie_image_url,
      json.main_image_url,
      json.has_wish_menu,
      json.has_memorial_menu,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.email,
      json.website,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Temple> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/temples/${id}`,
      config
    );

    console.log(response);

    const json = response.data as TempleJson;

    return new Temple(
      json.id,
      json.username,
      json.name,
      json.kana,
      json.selfie_image_url,
      json.main_image_url,
      json.has_wish_menu,
      json.has_memorial_menu,
      json.postcode,
      json.prefecture,
      json.city,
      json.address,
      json.building,
      json.telephone,
      json.email,
      json.website,
      json.bank_code,
      json.bank_name,
      json.branch_code,
      json.branch_name,
      json.account_type,
      json.account_no,
      json.account_name,
      json.follower_count,
      json.post_count,
      json.timeline_published_time,
      json.read_time,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
