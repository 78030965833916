import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/plugins/amplify";
import "@/plugins/yup";
import "@/assets/index.css";

import FontAwesomeIcon from "@/plugins/font-awesome";

createApp(App)
  .use(store) //
  .use(router) //
  .component("fa", FontAwesomeIcon)
  .mount("#app");
