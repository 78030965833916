interface PrefectureJson {
  code: string;
  name: string;
}

export default class Prefecture {
  code: string;
  name: string;
  kana: string;

  constructor(code: string, name: string, kana: string) {
    this.code = code;
    this.name = name;
    this.kana = kana;
  }

  static async search(keyword: string): Promise<Prefecture[]> {
    console.log(`Prefecture.search(${keyword})`);

    if (!keyword) {
      return Prefecture.all();
    }

    return Prefecture.all().filter(
      (item) =>
        item.name.indexOf(keyword) >= 0 || item.kana.indexOf(keyword) >= 0
    );
  }

  static all(): Prefecture[] {
    return [
      new Prefecture("01", "北海道", "ホッカイドウ"),
      new Prefecture("02", "青森県", "アオモリ"),
      new Prefecture("03", "岩手県", "イワテ"),
      new Prefecture("04", "宮城県", "ミヤギ"),
      new Prefecture("05", "秋田県", "アキタ"),
      new Prefecture("06", "山形県", "ヤマガタ"),
      new Prefecture("07", "福島県", "フクシマ"),
      new Prefecture("08", "茨城県", "イバラキ"),
      new Prefecture("09", "栃木県", "トチギ"),
      new Prefecture("10", "群馬県", "グンマ"),
      new Prefecture("11", "埼玉県", "サイタマ"),
      new Prefecture("12", "千葉県", "チバ"),
      new Prefecture("13", "東京都", "トウキョウ"),
      new Prefecture("14", "神奈川県", "カナガワ"),
      new Prefecture("15", "新潟県", "ニイガタ"),
      new Prefecture("16", "富山県", "トヤマ"),
      new Prefecture("17", "石川県", "イシカワ"),
      new Prefecture("18", "福井県", "フクイ"),
      new Prefecture("19", "山梨県", "ヤマナシ"),
      new Prefecture("20", "長野県", "ナガノ"),
      new Prefecture("21", "岐阜県", "ギフ"),
      new Prefecture("22", "静岡県", "シズオカ"),
      new Prefecture("23", "愛知県", "アイチ"),
      new Prefecture("24", "三重県", "ミエ"),
      new Prefecture("25", "滋賀県", "シガ"),
      new Prefecture("26", "京都府", "キョウト"),
      new Prefecture("27", "大阪府", "オオサカ"),
      new Prefecture("28", "兵庫県", "ヒョウゴ"),
      new Prefecture("29", "奈良県", "ナラ"),
      new Prefecture("30", "和歌山県", "ワカヤマ"),
      new Prefecture("31", "鳥取県", "トットリ"),
      new Prefecture("32", "島根県", "シマネ"),
      new Prefecture("33", "岡山県", "オカヤマ"),
      new Prefecture("34", "広島県", "ヒロシマ"),
      new Prefecture("35", "山口県", "ヤマグチ"),
      new Prefecture("36", "徳島県", "トクシマ"),
      new Prefecture("37", "香川県", "カガワ"),
      new Prefecture("38", "愛媛県", "エヒメ"),
      new Prefecture("39", "高知県", "コウチ"),
      new Prefecture("40", "福岡県", "フクオカ"),
      new Prefecture("41", "佐賀県", "サガ"),
      new Prefecture("42", "長崎県", "ナガサキ"),
      new Prefecture("43", "熊本県", "クマモト"),
      new Prefecture("44", "大分県", "オオイタ"),
      new Prefecture("45", "宮崎県", "ミヤザキ"),
      new Prefecture("46", "鹿児島県", "カゴシマ"),
      new Prefecture("47", "沖縄県", "オキナワ"),
    ];
  }
}
