import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { MediaResource, MediaResourceJson } from "@/models/MediaResource";

interface PrayingJson {
  id: string;
  decedent_id: string;
  customer_id: string;
  customer_name: string;
  customer_username: string;
  customer_selfie_image_url: string | null;
  customer_relationship: string | null;
  stamp_type: string;
  created_at: string;
}

export default class Praying {
  id: string;
  decedent_id: string;
  customer_id: string;
  customer_name: string;
  customer_username: string;
  customer_selfie_image_url: string | null;
  customer_relationship: string | null;
  stamp_type: string;
  created_at: Date;

  is_posting = false;

  constructor(
    id: string,
    decedent_id: string,
    customer_id: string,
    customer_name: string,
    customer_username: string,
    customer_selfie_image_url: string | null,
    customer_relationship: string | null,
    stamp_type: string,
    created_at: Date
  ) {
    this.id = id;
    this.decedent_id = decedent_id;
    this.customer_id = customer_id;
    this.customer_name = customer_name;
    this.customer_username = customer_username;
    this.customer_selfie_image_url = customer_selfie_image_url;
    this.customer_relationship = customer_relationship;
    this.stamp_type = stamp_type;
    this.created_at = created_at;
  }

  static async search(
    decedentId: string,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<Praying[]> {
    console.log(
      `Praying.search(${decedentId}, ${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        decedent_id: decedentId,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/prayings`,
      config
    );

    const list: Praying[] = [];
    response.data.data.forEach((json: PrayingJson) => {
      list.push(
        new Praying(
          json.id,
          json.decedent_id,
          json.customer_id,
          json.customer_name,
          json.customer_username,
          json.customer_selfie_image_url,
          json.customer_relationship,
          json.stamp_type,
          new Date(json.created_at)
        )
      );
    });

    return list;
  }

  static async register(
    decedentId: string,
    customerId: string,
    type: string
  ): Promise<Praying> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/prayings`,
      {
        decedent_id: decedentId,
        customer_id: customerId,
        stamp_type: type,
      },
      config
    );

    console.log(response);

    const json = response.data.data as PrayingJson;

    return new Praying(
      json.id,
      json.decedent_id,
      json.customer_id,
      json.customer_name,
      json.customer_username,
      json.customer_selfie_image_url,
      json.customer_relationship,
      json.stamp_type,
      new Date(json.created_at)
    );
  }

  static async delete(id: string): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/prayings/${id}`,
      config
    );

    console.log(response);

    return response.data.code == "0";
  }
}
